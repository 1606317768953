import React, { Component } from "react";


import AlphaLogo from "../../assets/images/alpha-logo-big.png";
import UpBorder from "../../assets/images/white-up-border.png";
import DeliveryImg from "../../assets/images/Delivery.png";

import "./Home.scss";

class HeaderSection extends Component {


    render() {
        return (
            <div className="Home-header">
                <div className='Home-header-container flex flex-1 content-container'>
                    <div className="flex flex-70 flex-col items-center just-between">
                        <div style={{ width: "100%", }} className="flex items-center just-end" >
                            <a href="https://wolt.com/de/deu/frankfurt/restaurant/taverna-alpha">
                                <div className="Details-button-containter Details-button-containter-small">
                                    <img src={DeliveryImg} className="Button-icon" style={{ width: "13vmin" }} />
                                </div>
                            </a>
                        </div>
                        <img src={AlphaLogo} className="AlphaLogo-img" alt="Alpha logo black" />
                        <div style={{ height: "20%" }}></div>
                    </div>
                    <div className="flex items-center just-center">
                        {/*<div className="flex flex-1 flex-col items-center just-center Announcement-container">
                             <b>Wir sind vom 23.12.2024 bis einschließlich 02.01.2025 geschlossen. Ab dem 03.01.2025 sind wir wieder da! Zum reservieren gerne eine Email schicken! <br /> <br /> Wir wünschen frohe Weihnachten und einen guten Rutsch ins neue Jahr! 🎅 <br />Taverna Alpha</b> 
                        </div>*/}
                    </div>
                </div>
                <div className="Bottom-Border">
                    <img src={UpBorder} className="Border-img" alt="Brush border bottom" />
                </div>
            </div>
        );
    }
}

export default HeaderSection;